import { type Provider } from '@angular/core';
import { TRANSLOCO_SCOPE, type ProviderScope, type Translation } from '@jsverse/transloco';
import { scopeLoader } from './scope-loader';

/**
 * Use in components or modules to provide the translation scope and importer function for lazy loaded libs
 * @param {string} scope Name of translation scope
 * @param {(lang: string, root: string) => Promise<Translation>} importer Async arrow function using `import` statement pointing to the translation assets
 * @returns {Provider[]} Composed provider array
 * @example
 * provideTranslationScope('scopeName', async (lang: string, root: string) => import(`./path/to/translation/file/${root}/${lang}.json`)),
 */
export const provideTranslationScope = (scope: string, importer: (lang: string, root: string) => Promise<Translation>): Provider[] => [
    {
        provide: TRANSLOCO_SCOPE,
        useValue: {
            scope,
            loader: scopeLoader(importer),
        } as ProviderScope,
    },
];
