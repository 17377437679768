import { type HashMap, type Translation } from '@jsverse/transloco';
import { availableLangs } from './available-langs';

/**
 * Used internally in `provideTranslationScope`
 * Takes care of lazy loading the scoped translation files for each lib
 * @param {(lang: string, root: string) => Promise<Translation>} importer Async arrow function using import pointing to the translation assets of scoped lib
 * @param {string} root Default folder name `i18n`
 * @returns {HashMap<() => Promise<Translation>>} Loaded translation json file
 * @example
 * providers: [
        {
            provide: TRANSLOCO_SCOPE,
            useValue: {
                scope: 'main',
                loader: scopeLoader(async (lang: string, root: string) => import(`./../assets/${root}/${lang}.json`)),
            },
        },
    ],
 */
export const scopeLoader = (importer: (lang: string, root: string) => Promise<Translation>, root = 'i18n'): HashMap<() => Promise<Translation>> =>
    availableLangs.reduce((acc: HashMap<() => Promise<Translation>>, lang) => {
        acc[lang] = async (): Promise<Translation> => importer(lang, root);

        return acc;
    }, {});
